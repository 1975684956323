import React from "react"

export const Assistance = ({
  policy = false,
  spanish = false
}: {
  policy?: boolean
  spanish?: boolean
}) => {
  return spanish ? (
    <>
      <p className="ss2 contact">
        Para asistencia:
        <span>
          800-435-4343 <span></span>
          <a href="mailto:service@ligonier.org">service@ligonier.org</a>
        </span>
      </p>
      <p className="ss5">
        <a href="https://www.ligonier.org/privacy-policy/" target="blank">
          Política de Privacidad
        </a>
      </p>

      {policy && (
        <p className="ss5 mt-5">
          Nuestra política es aplicar los donativos otorgados a un programa
          específico. Si recibimos más contribuciones de las que se pueden
          utilizar sabiamente para un proyecto determinado, aplicamos estos
          fondos para satisfacer otras necesidades apremiantes en nuestros
          esfuerzos de alcance. Estamos comprometidos a administrar todos los
          donativos sabiamente para alcanzar la meta de proclamar la santidad de
          Dios a tantas personas como sea posible. Ligonier Ministries es
          miembro del Consejo Evangélico para la Responsabilidad Financiera.
        </p>
      )}
    </>
  ) : (
    <>
      <p className="ss2 mt-5 contact">
        If you need assistance, please call{" "}
        <a href="tel:8004354343">800-435-4343</a> or{" "}
        <a href="mailto:service@ligonier.org">email</a> us.
      </p>

      {policy && (
        <p className="ss5 mt-5">
          Our policy is to apply gifts given toward a specific program. If we
          receive more contributions than can be wisely used for a given
          project, we apply these funds to meet other pressing needs in our
          outreach efforts. We are committed to stewarding all gifts wisely
          toward the goal of proclaiming the holiness of God to as many people
          as possible. Ligonier Ministries is a member of the Evangelical
          Council for Financial Accountability.
        </p>
      )}
    </>
  )
}
