import { useContext, useEffect, useState } from "react"
import { ServiceContext } from "../../pages/[id]/[slug]"
import { minimumMinistryPartnerDonation, defaultMonthlyGivingAmount, brandHasStreamingBenefit } from "../../utils/helpers"
import { Campaign } from "../../types"
import { PaymentSelector } from "./PaymentSelector"

export default function Recurring({ campaign }: { campaign: Campaign}) {
  const [context, setContext] = useState(null)
  const { recurringService } = useContext(ServiceContext)

  const showMinistryPartnerStreamingBenefits = brandHasStreamingBenefit(campaign.brand)

  useEffect(() => {
    const subscription = recurringService.subscribe((state) => {
      setContext(state.context)
      setIsMinistryPartnerDetailsVisible(state.context.values.ministryPartnerChecked)
    })

    return subscription.unsubscribe
  }, [recurringService])

  const [isMinistryPartnerDetailsVisible, setIsMinistryPartnerDetailsVisible] =
    useState(false)
    
  return (
    <>
      <div
        id="recurring"
        className="mb-3 justify-center items-center flex flex-col"
      >
          <PaymentSelector donationService={recurringService} campaign={campaign} amounts={campaign.recurring_amounts} />

          <div id="recurring-details" className="mt-4 block">
            {(context?.values?.chosenAmount ?? 0) >=
              minimumMinistryPartnerDonation && (
              <div id="partnership" className="mt-2">
                <div className="flex flex-col justify-center items-center">
                  <div>
                    Your monthly gift qualifies you to become a Ministry
                    Partner.
                    <button
                      type="button"
                      id="learn-more-btn"
                      className="ml-1"
                      aria-label="learn more about ministry partners"
                      onClick={() =>
                        setIsMinistryPartnerDetailsVisible((value) => !value)
                      }
                    >
                      <svg
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </button>
                  </div>

                  {isMinistryPartnerDetailsVisible && (
                    <div id="ministry-partner-details" className="mb-2">
                      <h2>Ministry Partners receive:</h2>
                      <ul className="ml-2 mt-1">
                        {showMinistryPartnerStreamingBenefits && (<li>
                          Digital access to all of Ligonier’s teaching series
                        </li>)}
                        <li>
                          An ongoing <em>Tabletalk</em> magazine subscription
                        </li>
                        <li>Special discounts on resources and events</li>
                        <li>
                          An ESV <em>Reformation Study Bible</em>
                        </li>
                        <li>Updates on what your support accomplishes</li>
                        <li>Exclusive monthly messages</li>
                      </ul>
                    </div>
                  )}

                  <div id="ministry-partner-options">
                    <input
                      type="checkbox"
                      id="ministry-partner"
                      name="ministry_partner"
                      className="sr-only"
                      checked={context?.values?.ministryPartnerChecked ?? false}
                      onChange={() =>
                        recurringService.send({
                          type: "CHANGE",
                          key: "ministryPartnerChecked",
                          value: !context.values.ministryPartnerChecked
                        })
                      }
                    />
                    <label
                      htmlFor="ministry-partner"
                      className="mt-3"
                      id="ministry-partner-label"
                    >
                      I would like to become a Partner today.
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="mb-2 flex justify-center items-center date-selection">
              <span>Schedule my future gifts to be deducted on the&nbsp;</span>
              <label htmlFor="recur_on" className="sr-only">
                Schedule my future gifts for which day of the month?
              </label>
              <select
                name="recur_on"
                id="recur_on"
                onChange={(e) =>
                  recurringService.send({
                    type: "CHANGE",
                    key: "recurOnDay",
                    value: e.target.value
                  })
                }
              >
                <option value="1">1st</option>
                <option value="15">15th</option>
              </select>
              <span>&nbsp;of the month.</span>
            </div>
          </div>
      </div>
    </>
  )
}
