import { useContext, useEffect, useState } from "react"
import { Assistance } from "./Assistance"
import { ECFA } from "./ECFA"
import { Outreach } from "./Outreach"
import { Recaptcha } from "./Recaptcha"
import { Campaign } from "../types"
import { ServiceContext } from "../pages/[id]/[slug]"
import { useSession, signOut } from "next-auth/react"

const SignOut = () => {
  const { data: session } = useSession()

  // The presence of this env allows us to test the form completion and payment methods
  // feature internally on staging environments where signing out might be required
  // multiple times in order to test.
  if (process.env.NEXT_PUBLIC_ALLOW_SIGN_OUT !== "true") {
    return null
  }

  if (session) {
    return (
      <div className="row ss4">
        <div className="col-100">
          <p className="ss4 mb-1">{session.user.email}</p>
          <div>
            <button
              className="btn small"
              onClick={(e) => {
                e.preventDefault()
                signOut()
              }}
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

const renderFooter = (campaign: Campaign, lang: string) => {
  const isFree = campaign?.offer?.free

  if (lang === "es-US") {
    return (
      <>
        <div className="col-60 col-100-m center-m col-order-2-m">
          {!isFree ? (
            <>
              <Assistance policy spanish />
            </>
          ) : (
            <Assistance spanish />
          )}
        </div>
        <ECFA />
        <Recaptcha spanish />
        <Outreach />
      </>
    )
  }

  switch (campaign.brand) {
    case "Ligonier Ministries Canada":
      return (
        <>
          <div className="col-60 col-100-m center-m col-order-2-m">
            <p className="ss5 contact">If you prefer, mail your donation to:</p>
            <p className="ss5 mt-3 contact">
              Ligonier Ministries Canada
              <br />
              PO Box 1200, Ayr, ON N0B 1E0
            </p>
            <p className="ss5 mt-3 contact">
              If you need assistance, please call{" "}
              <a href="tel:800-733-0408">800-733-0408</a> or email us at{" "}
              <a href="mailto:service@ligonier.ca">service@ligonier.ca</a>.
            </p>
            <p className="ss5 mt-3">
              <a href="https://www.ligonier.org/privacy-policy/" target="blank">
                Privacy Policy
              </a>
            </p>
            {!isFree && (
              <p className="ss5 mt-5">
                Ligonier Ministries Canada, a Canadian Registered Charity,
                issues tax-deductible receipts on an annual basis for the total
                amount of all eligible donations given to this Ministry
                throughout the year. Your eligible tax-deductible gifts are
                limited to the excess amount of your gifts given over the fair
                market value (or value of advantage) of any goods and services
                provided to you by the Ministry. Spending of funds is confined
                to programs and projects approved by the Canadian Ministry. Each
                restricted contribution designated toward such an approved
                program or project will be used as designated with the
                understanding that when the need for such a program or project
                has been met, or cannot be completed for any reason determined
                by the Canadian Ministry, the remaining restricted contributions
                will be used where needed most.
              </p>
            )}
          </div>
          <ECFA />
          <Recaptcha />
          <Outreach canada />
        </>
      )
    case "Reformation Bible College":
      return (
        <>
          <div className="col-60 col-100-m center-m col-order-2-m">
            {!isFree ? (
              <>
                <p className="ss2 contact">
                  If you prefer, mail your donation to:
                </p>
                <p className="ss2 mt-5 contact">Reformation Bible College</p>
                <p className="ss2 contact">
                  PO Box 947595, Atlanta, GA 30394-7595
                </p>
                <Assistance policy />
              </>
            ) : (
              <Assistance />
            )}
          </div>
          <Outreach />
        </>
      )
    default:
      return (
        <>
          <div className="col-60 col-100-m center-m col-order-2-m">
            {!isFree ? (
              <>
                <p className="ss2 contact">
                  If you prefer, mail your donation to:
                </p>
                <p className="ss2 mt-5 contact">Ligonier Ministries</p>
                <p className="ss2 contact">
                  PO Box 947595, Atlanta, GA 30394-7595
                </p>

                <p className="ss2 mt-5 contact">
                  If mailing overnight, please mail to:
                </p>
                <p className="ss2 mt-5 contact">Ligonier Ministries</p>
                <p className="ss2 contact">
                  451 Ligonier Court, Sanford, FL 32771-8608
                </p>
                <Assistance policy />
              </>
            ) : (
              <Assistance />
            )}
          </div>
          <ECFA />
          <Recaptcha />
          <Outreach />
        </>
      )
  }
}

export default function Footer({ campaign }: { campaign: Campaign }) {
  const { languageService } = useContext(ServiceContext)
  const [lang, setLang] = useState<string | null>(null)

  useEffect(() => {
    const subscription = languageService.subscribe((state) => {
      setLang(state.context.lang)
    })

    return subscription.unsubscribe
  }, [languageService])

  return (
    <>
      <footer id="footer" className="pb-6">
        <div className="container">
          <div className="row">{renderFooter(campaign, lang)}</div>
          <SignOut />
        </div>
      </footer>
    </>
  )
}
