import { useContext, useState, useEffect } from "react"
import { ServiceContext } from "../pages/[id]/[slug]"

interface PrivacyPolicyProps {
  onAccept: () => void
  onDecline: () => void
  isVisible: boolean
}

export default function PrivacyPolicy({
  onAccept,
  onDecline,
  isVisible
}: PrivacyPolicyProps) {
  const { languageService } = useContext(ServiceContext)
  const [lang, setLang] = useState<string | null>(null)

  useEffect(() => {
    const subscription = languageService.subscribe((state) => {
      setLang(state.context.lang)
    })

    return subscription.unsubscribe
  }, [languageService])

  return isVisible ? (
    lang === "es-US" ? (
      <div id="pp-modal">
        <div id="pp-modal-content">
          <div id="pp-modal-sub-content">
            <h2 className="t4 mb-1">Confirmación Requerida</h2>

            <p>
              Hemos actualizado nuestros Términos de Servicio efectivo en Mayo
              1, 2018. Nuestro compromiso con tu privacidad y la protección de
              tu data no ha cambiado. Por favor confirma que has leído y aceptas
              nuestra nueva{" "}
              <a
                href="https://www.ligonier.org/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidad
              </a>
              .
            </p>

            <div id="pp-btns">
              <button
                id="accept-pp-btn"
                className="btn mb-4"
                onClick={onAccept}
              >
                Aceptar
              </button>

              <button
                id="decline-pp-btn"
                className="btn mb-4"
                onClick={onDecline}
              >
                Declinar
              </button>
            </div>

            <p className="ss5">
              Utilizamos varias tecnologías de Internet para personalizar tu
              experiencia con nuestro ministerio a fin de servirte mejor. Para
              más información, consulta nuestra{" "}
              <a
                href="https://www.ligonier.org/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidad
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    ) : (
      <div id="pp-modal">
        <div id="pp-modal-content">
          <div id="pp-modal-sub-content">
            <h2 className="t4 mb-1">Confirmation Required</h2>
            <p>
              We use several internet technologies to customize your experience
              with our ministry in order to serve you better. To learn more,
              view our{" "}
              <a
                href="https://www.ligonier.org/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
            <div id="pp-btns">
              <button
                id="accept-pp-btn"
                className="btn mb-4"
                data-testid="pp-accept-button"
                onClick={onAccept}
              >
                Accept
              </button>
              <button
                id="decline-pp-btn"
                className="btn mb-4"
                onClick={onDecline}
              >
                Decline
              </button>
            </div>
            <p className="ss5">
              You won&apos;t be able to submit your form unless you accept our
              new{" "}
              <a
                href="https://www.ligonier.org/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    )
  ) : null
}
