import { Field } from "formik"
import Image from "next/image"
import { useContext, useEffect, useState } from "react"
import ReactHtmlParser from "react-html-parser"
import { ServiceContext } from "../../pages/[id]/[slug]"
import {
  getBadge,
  getDisclaimer,
  getImgIXUrl,
  getIndividualFairMarketValue,
  isDownloadable,
  isShippable,
  toReadable
} from "../../utils/helpers"
import Countdown from "./Countdown"

function ListedProducts({ products }: { products: any }) {
  const { productCodesService } = useContext(ServiceContext)
  const [context, setContext] = useState(null)

  useEffect(() => {
    const subscription = productCodesService.subscribe((state) => {
      setContext(state.context)
    })

    return subscription.unsubscribe
  }, [productCodesService])

  if (!products) return

  const handleChange = (selectedProduct) => (e) => {
    productCodesService.send({
      type: "CHANGE",
      value: e.target.value,
      selectedProduct
    })
  }

  const isOnlyOneProductWithVariations =
    products.length == 1 && products[0].variations.length > 0

  return isOnlyOneProductWithVariations
    ? products?.map((product) => {
        return product?.variations.map((variation) => {
          return (
            <>
              <li
                data-fair-market-value={getIndividualFairMarketValue(variation)}
              >
                <Field
                  id={`product_${variation.code}`}
                  type="radio"
                  name="product_code"
                  checked={context?.product_codes?.includes(variation.code)}
                  value={variation.code}
                  onChange={handleChange(variation)}
                />
                <label htmlFor={`product_${variation.code}`}>
                  <div className="image-object-wrapper">
                    <Image
                      layout="responsive"
                      width={560}
                      height={350}
                      src={getImgIXUrl(
                        variation.image.three_dimensional ||
                          variation.image.flat,
                        "small"
                      )}
                      alt={variation.name}
                      objectFit="contain"
                    />
                  </div>
                  <strong>{variation.name}</strong>
                  <span>
                    Format: {variation.format} |{" "}
                    {variation.teachers.length
                      ? `by ${toReadable(variation.teachers)}`
                      : ""}
                  </span>
                </label>
              </li>
            </>
          )
        })
      })
    : products?.map((product, index) => {
        const imageUrl = getImgIXUrl(
          product.image.three_dimensional || product.image.flat,
          "small"
        )
        return (
          <>
            <li data-fair-market-value={getIndividualFairMarketValue(product)}>
              <input
                id={`product_${product.code}`}
                type="checkbox"
                name={`product_codes[${index}]`}
                value={product.code}
                checked={context?.product_codes?.includes(product.code)}
                onChange={handleChange(product)}
              />
              <label htmlFor={`product_${product.code}`}>
                <div className="image-object-wrapper">
                  {imageUrl && (
                    <Image
                      layout="responsive"
                      width={560}
                      height={350}
                      src={imageUrl}
                      alt={product.name}
                      objectFit="contain"
                    />
                  )}
                </div>
                <strong>{product.name}</strong>
                {product.teachers.length ? (
                  <span>by {toReadable(product.teachers)}</span>
                ) : (
                  ""
                )}
                <span>{ReactHtmlParser(getBadge(product))}</span>
              </label>
            </li>
          </>
        )
      })
}

function ProductList({ campaign }) {
  return (
    <ol
      id="pick-x-of-y-list"
      data-allowed-products="{campaign.offer.allowed_products}"
    >
      <ListedProducts products={campaign.offer.products} />
      <p className="ss5 center">
        {getDisclaimer(
          campaign,
          isShippable(campaign),
          isDownloadable(campaign.offer.products[0])
        )}
      </p>
    </ol>
  )
}

export default function ValidCampaignOffer({ campaign }) {
  return (
    <>
      <ProductList campaign={campaign} />
      <Countdown campaign={campaign} />
    </>
  )
}
