import Image from "next/image"
import { getQuote, getQuoteBy, slugize } from "../../utils/helpers"
import { Campaign } from "../../types"
import ReactHtmlParser from "react-html-parser"

export default function NoCampaignOffer({ campaign }: { campaign: Campaign }) {
  const isAdminCampaign = ["481", "482"].includes(campaign.id)

  return isAdminCampaign ? (
    <>
      <div className="image-holder mt-5">
        <div className="image-object-wrapper">
          <Image
            layout="responsive"
            width={560}
            height={350}
            src={campaign.social_image}
            alt="Social Image"
            objectFit="contain"
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <blockquote
        className={`quote ${slugize(getQuoteBy(campaign))} pa-7 hide-s`}
      >
        <p>{ReactHtmlParser(getQuote(campaign))}</p>
        <cite>{getQuoteBy(campaign)}</cite>
      </blockquote>
    </>
  )
}
