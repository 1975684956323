import { useState, SyntheticEvent } from "react"
import classNames from "classnames"
import { Field, FormikTouched } from "formik"
import { validationTypes } from "../../utils/validation"
import { FieldValues } from "./Fields"

interface FieldTextInputProps {
  value: string
  className?: string
  required?: boolean
  name: string
  id?: string
  label: string
  type?: string
  autoComplete?: string
  dataValidation?: string
  autoCorrect?: "on" | "off"
  spellCheck?: boolean
  error: string
  touched: FormikTouched<FieldValues>
  onChange: (e) => void
  onBlur: (e) => void
}

export const FieldTextInput = ({
  className,
  required = true,
  name,
  id,
  label,
  value,
  type = "text",
  autoComplete,
  dataValidation,
  autoCorrect = "off",
  spellCheck = false,
  error,
  touched,
  onChange,
  onBlur
}: FieldTextInputProps) => {
  function getTouchedNestedField(touched, name) {
    // For nested fields we have a stucture like address[address1]
    // we need to split this into two keys - address & address1
    const key1 = name.split("[")[0]
    const key2 = name.split("[")[1].slice(0, -1)

    // then access touched object with the keys touched[address][address1]
    return touched[key1] && touched[key1][key2]
  }

  const isTouched = Boolean(
    !name.includes("[") ? touched[name] : getTouchedNestedField(touched, name)
  )
  const [isFocused, setIsFocused] = useState(false)

  const isValidOrNotEmpty = value || (value && !error)

  return (
    <>
      <label
        className={classNames(
          isFocused && "focused",
          error && isTouched && "error",
          isValidOrNotEmpty && "valid"
        )}
        htmlFor={id || name}
      >
        {error && isTouched ? error : label}
      </label>
      <Field
        className={classNames(
          "input",
          isFocused && "focused",
          isValidOrNotEmpty && "valid",
          className
        )}
        required={required}
        name={name}
        id={id || name}
        type={type}
        data-validation={dataValidation}
        x-autocompletetype={autoComplete || ""}
        autoComplete={autoComplete || ""}
        autoCorrect={autoCorrect}
        spellCheck={spellCheck}
        onChange={onChange}
        onBlur={(e: SyntheticEvent) => {
          setIsFocused(false)
          onBlur(e)
        }}
        onFocus={() => setIsFocused(true)}
        validate={(value) => {
          if (!value && required) {
            return label
          } else if (value && dataValidation) {
            if (dataValidation.includes(".")) {
              // Currently this pattern only matches the postcode.[country_code] validation function
              const [validation, subValidation] = dataValidation.split(".")

              return validationTypes[validation](value, subValidation)
            } else {
              return validationTypes[dataValidation](value)
            }
          } else {
            // Explicit return here to show that that the validation function
            // is returning no error in this case
            return undefined
          }
        }}
      />
    </>
  )
}
