import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import Image from "next/image"
import ReactHtmlParser from "react-html-parser"
import {
  getBadge,
  getHero,
  getHeroImg,
  getImgIXUrl,
  isDigital
} from "../../utils/helpers"
import { Campaign } from "../../types"

const PropValue = ({ hero, campaign, countdown }) => (
  <>
    <Link href="/" passHref={true}>
      <a
        id="redirect-message"
        className="block pa-2 bg-putty-faint charcoal center"
      >
        Redirecting to an active page in{" "}
        <em id="redirect-countdown">{countdown}</em> seconds.
      </a>
    </Link>
    <div className="offer-value-proposition pa-5">
      {campaign.offer?.products.length > 1 ? (
        <>
          <h2 className="format mb-5">
            <span>
              {ReactHtmlParser(getBadge(campaign.offer.products[0], campaign))}
            </span>
          </h2>
          <div className="mb-5">
            <Image
              layout="responsive"
              width={560}
              height={350}
              src={getImgIXUrl(campaign.offer.image, "large")}
              alt={campaign.offer.products[0].name}
              objectFit="contain"
            />
          </div>
        </>
      ) : (
        <>
          <h2 className="format mb-5">
            <span>{ReactHtmlParser(getBadge(hero, campaign))}</span>
          </h2>
          <div className={`mb-5 ${isDigital(campaign) ? "digital" : ""}`}>
            <Image
              layout="responsive"
              width={560}
              height={350}
              src={getHeroImg(campaign)}
              alt={hero.name}
              className={`${isDigital(campaign) ? "digital" : ""}`}
              objectFit="contain"
            />
          </div>
        </>
      )}
    </div>
  </>
)

const CampaignOffer = ({ campaign, countdown }) => {
  const free = campaign?.offer?.free
  const hero = getHero(campaign)

  return (
    <>
      {free ? (
        <h1 className="t1 mv-7">
          We&apos;re Sorry, this Free Resource Offer Has Expired.
        </h1>
      ) : (
        <h1 className="t1 mv-7">
          We&apos;re Sorry, this Resource Offer for a Gift of Any Amount Has
          Expired.
        </h1>
      )}
      <PropValue countdown={countdown} campaign={campaign} hero={hero} />
    </>
  )
}

const FreeCampaign = ({ purpose, countdown }) => (
  <>
    <h1 className="t1 mv-7" style={{ textTransform: "capitalize" }}>
      We&apos;re Sorry, the {purpose} Fundraiser Has Expired.
    </h1>
    <Link href="/" passHref={true}>
      <a
        id="redirect-message"
        className="block pa-2 bg-putty-faint charcoal center"
      >
        Redirecting to an active page in{" "}
        <em id="redirect-countdown">{countdown}</em> seconds.
      </a>
    </Link>
  </>
)

type ExpiredProps = {
  campaign: Campaign
}

export default function Expired({ campaign }: ExpiredProps) {
  const [countdown, setCoundown] = useState(5)
  const router = useRouter()

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (countdown > 0) {
        setCoundown((countdown) => countdown - 1)
      } else {
        router.push("/")
      }
    }, 1000)

    // clean up the interval when this component unmounts
    return () => clearInterval(countdownInterval)
  }, [countdown, router])

  return (
    <>
      <div id="expired" className="container center">
        <div className="row">
          <div className="col-50 col-75-l pt-5">
            <a className="logo" href={process.env.NEXT_PUBLIC_DOMAIN}>
              {campaign.brand}
            </a>
            {campaign.offer ? (
              <CampaignOffer campaign={campaign} countdown={countdown} />
            ) : (
              <FreeCampaign purpose={campaign?.purpose} countdown={countdown} />
            )}
            <footer id="footer" className="mv-5">
              <p className="ss2 contact">
                For assistance, call 800-435-4343 or{" "}
                <a href="mailto:service@ligonier.org">email us</a>.
              </p>
              <p className="ss5">
                An Outreach of{" "}
                <a
                  href="https://www.ligonier.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ligonier
                </a>{" "}
                <span className="copyright">© {new Date().getFullYear()}</span>
              </p>
            </footer>
          </div>
        </div>
      </div>
    </>
  )
}
