import { useEffect, useState } from "react"

export type PaymentSelectorProps = {
  donationService: any
  campaign: any
  amounts: any
}

export function PaymentSelector({
  donationService,
  campaign,
  amounts
}: PaymentSelectorProps) {
  const [context, setContext] = useState(null)
  const [customAmount, setCustomAmount] = useState("")

  useEffect(() => {
    const subscription = donationService.subscribe((state) => {
      setContext(state.context)
    })

    return subscription.unsubscribe
  }, [donationService])

  const contextAmount = context?.values?.amount || null
  const contextAmountSelected = context?.values?.amountSelected || null 

  return (
    <>
      <nav id="amounts">
        {amounts?.map((amount) => {
          return (
            <a
              href="#"
              key={amount}
              className={amount === contextAmountSelected ? "active" : ""}
              aria-label={`Choose ${amount} to donate`}
              onClick={(e) => {
                e.preventDefault()
                donationService.send({
                  type: "CHANGE",
                  key: "amountSelected",
                  value: amount
                })
                setCustomAmount("")
              }}
            >
              {amount}
            </a>
          )
        })}
        <span id="input-wrap">
          {/*
            It looks like width-machine and the 99 is to determine the width on the input so it can continue to grow.
            It always stays with 2 digits to always have 2 digit width.
            */}
          <span id="width-machine" aria-hidden="true">
            999
          </span>
          <div>
            <label htmlFor="amount-input" className="sr-only">
              {campaign.language === "es-US" ? "Otro" : "Other"}
            </label>
            <input
              id="amount-input"
              name="amount-input"
              className={`amount ${contextAmount ? "active" : ""}`}
              type="tel"
              data-validation="money"
              onChange={(e) => {
                const value = e.target.value

                // Prevents user from trying to delete the $ symbol and prevents typing more than 2 decimal places
                if (!value.match(/^\$(\d+(\.\d{0,2})?)?$/)) {
                  return
                }

                if (value === "" || value.startsWith("$")) {
                  setCustomAmount(value)
                  donationService.send({
                    type: "CHANGE",
                    key: "amount",
                    value: value === "$" ? "" : value,
                  })
                }
              }}
              onFocus={() => {
                if (customAmount === "") {
                  setCustomAmount("$")
                }
              }}
              onBlur={() => {
                if (customAmount === "$") {
                  setCustomAmount("")
                }
              }}
              value={customAmount || contextAmount || ""}
              placeholder={campaign.language === "es-US" ? "Otro" : "Other"}
            />
          </div>
        </span>
      </nav>
    </>
  )
}
