import React from "react"

export function Outreach({ canada = false }: { canada?: boolean }) {
  const year = new Date().getFullYear()

  return canada ? (
    <div className="col-100 col-order-3-m">
      <p className="ss5 center-m">
        An Outreach of{" "}
        <a href="https://www.ligonier.ca/" target="_blank" rel="noreferrer">
          Ligonier Ministries Canada
        </a>{" "}
        <span className="copyright">© {year}</span>
      </p>
    </div>
  ) : (
    <div className="col-100 col-order-3-m">
      <p className="ss5 center-m">
        An Outreach of{" "}
        <a href="https://www.ligonier.org" target="_blank" rel="noreferrer">
          Ligonier
        </a>{" "}
        <span className="copyright">© {year}</span> |{" "}
        <a
          href="https://www.ligonier.org/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </p>
    </div>
  )
}
