import classNames from "classnames"
import { FormikTouched } from "formik"
import { useState, ReactElement } from "react"
import { FieldValues } from "./Fields"

interface FieldSelectInputProps {
  value?: string
  options: Array<unknown | string>
  className?: string
  labelClassName?: string
  labelId?: string
  required?: boolean
  name: string
  id?: string
  label: string
  type?: string
  autoComplete?: string
  dataValidation?: string
  autoCorrect?: "on" | "off"
  spellCheck?: boolean
  error?: string
  touched?: FormikTouched<FieldValues>
  renderOptions: (options: Array<unknown | string>) => ReactElement[]
  onChange: (options) => (e) => void
  onBlur?: (e) => void
}

export const FieldSelectInput = ({
  options,
  onChange,
  name,
  id,
  required = false,
  dataValidation,
  value,
  error,
  touched,
  label,
  onBlur,
  className,
  labelClassName,
  renderOptions,
  labelId
}: FieldSelectInputProps) => {
  function getTouchedNestedField(touched, name) {
    // For nested fields we have a stucture like address[address1]
    // we need to split this into two keys - address & address1
    const key1 = name.split("[")[0]
    const key2 = name.split("[")[1].slice(0, -1)

    // then access touched object with the keys touched[address][address1]
    return touched[key1] && touched[key1][key2]
  }

  const isTouched = Boolean(
    !name.includes("[") ? touched[name] : getTouchedNestedField(touched, name)
  )
  const [isFocused, setIsFocused] = useState(false)
  const isValidOrNotEmpty = value || (value && !error)

  return (
    <>
      <label
        htmlFor={id}
        id={classNames(labelId ? labelId : `${id}-label`)}
        className={classNames(
          "selects",
          isFocused && "focused",
          error && isTouched && "error",
          isValidOrNotEmpty && "valid",
          labelClassName
        )}
      >
        {error && isTouched ? error : label}
      </label>
      <select
        id={id}
        value={value}
        className={classNames(
          isFocused && "focused",
          isValidOrNotEmpty && "valid",
          className
        )}
        required={required}
        name={name}
        data-validation={dataValidation}
        onChange={(e) => {
          // we have to trigger blur and focus here
          !isFocused && setIsFocused(true)
          onBlur(e)
          onChange(options)(e)
        }}
      >
        <option value=""></option>
        {renderOptions(options)}
      </select>
    </>
  )
}
