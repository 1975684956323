import classNames from "classnames"
import { FormikErrors, FormikTouched } from "formik"
import { ChangeEvent, useState, FocusEvent, useContext, useEffect } from "react"
import { Campaign, CampaignFormValues } from "../../types"
import { getFairMarketValue, getIndividualFairMarketValue, getMPBenefitsFairMarketValue } from "../../utils/helpers"
import { getCountries } from "../../utils/support/countries"
import { FieldSelectInput } from "./FieldSelectInput"
import { FieldTextInput } from "./FieldTextInput"
import { Payment } from "./Payment"
import { GiftSummary } from "./GiftSummary"
import { useDonationAmount } from "../../hooks/useDonationAmount"
import { ServiceContext } from "../../pages/[id]/[slug]"

export type FieldValues = CampaignFormValues

const displayAmount = (amount: number) => `${amount.toFixed(2)}`

export interface FieldErrors {
  first_name: string
  last_name: string
  phone: string
  email: string
  bundle_code: string
  address:
    | string
    | {
        country: string
        state: string
        city: string
        address1: string
        address2: string
        postal_code: string
      }
}

type FieldsProps = {
  campaign: Campaign
  values: FieldValues
  errors: FormikErrors<FieldErrors>
  touched: FormikTouched<FieldValues>
  isFormValid: boolean
  isSubmitting: boolean
  handleChange: {
    (e: ChangeEvent<unknown>): void
    <T = string | ChangeEvent<unknown>>(
      field: T
    ): T extends ChangeEvent<unknown>
      ? void
      : (e: string | ChangeEvent<unknown>) => void
  }
  handleBlur: {
    (e: FocusEvent<Element>): void
    <T = unknown>(fieldOrEvent: T): T extends string
      ? (e: unknown) => void
      : void
  }
}

export default function Fields({
  isFormValid,
  isSubmitting,
  campaign,
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
}: FieldsProps) {
  const { productCodesService } = useContext(ServiceContext)
    const [context, setContext] = useState(null)

    useEffect(() => {
    const subscription = productCodesService.subscribe((state) => {
      setContext(state.context)
    })

    return subscription.unsubscribe
  }, [productCodesService])


  const [countrySubfieldConfig, setCountrySubfieldConfig] = useState({
    hasPostalCode: true,
    adminAreaLabel: "State",
    adminAreas: [],
    countryCode: "us"
  })

  const renderAdminAreas = (adminAreas: string[]) => {
    if (adminAreas.length) {
      return (
        <FieldSelectInput
          id="state"
          name="address[state]"
          dataValidation="select"
          required={true}
          className="state-select input-50"
          label={isEnglish ? countrySubfieldConfig.adminAreaLabel : "Estado"}
          options={countrySubfieldConfig.adminAreas}
          onChange={() => (e) => {
            handleChange(e)
          }}
          touched={touched}
          value={values.address?.["state"]}
          error={errors.address?.["state"]}
          onBlur={handleBlur}
          renderOptions={(options) =>
            options.map((state: string) => (
              <option key={state} value={state} label={state}>
                {state}
              </option>
            ))
          }
        />
      )
    } else {
      return (
        <FieldTextInput
          id="state"
          name="address[state]"
          className="input-50"
          label={isEnglish ? countrySubfieldConfig.adminAreaLabel : "Estado"}
          onChange={handleChange}
          onBlur={handleBlur}
          dataValidation="name"
          required={false}
          error={errors.address?.["state"]}
          touched={touched}
          value={values.address?.["state"]}
        />
      )
    }
  }

  const free = campaign?.offer?.free

  const isEnglish = campaign.language !== "es-US"

  const { donationTodayAmount, isMinistryPartnerAndHasValue, feesAndAmountCalculator } = useDonationAmount()
  const donationTodayAmountInt = Number(feesAndAmountCalculator(donationTodayAmount))

  
  let fairMarketValue;

  if (campaign?.offer?.allowed_products) {
    fairMarketValue = campaign.offer && context?.selectedProduct
      ? getIndividualFairMarketValue(context?.selectedProduct)+'' : '0'
  } else {
    fairMarketValue = getFairMarketValue(campaign)
  }

  const mpBenefitsFairMarketValue = isMinistryPartnerAndHasValue ? getMPBenefitsFairMarketValue(campaign) : 0.00

  const amountAndFairMarketDifference =
  donationTodayAmountInt - Number(fairMarketValue) - mpBenefitsFairMarketValue

  const taxDeductibleAmount = (
    (amountAndFairMarketDifference < 0 ? 0 : amountAndFairMarketDifference)
  ).toFixed(2)
  
  const mpBenefitsText = (mpBenefitsFairMarketValue ? (
    <> and the <em id="good-faith-estimate">{mpBenefitsFairMarketValue}</em> fair market value of Ministry Partner exclusive benefits</>
    ) : null)

  return (
    <>
      <fieldset className="stacked">
        <FieldTextInput
          name="first_name"
          label={isEnglish ? "First Name" : "Nombre"}
          autoComplete="given-name"
          onChange={handleChange}
          onBlur={handleBlur}
          dataValidation="name"
          autoCorrect="on"
          spellCheck={false}
          error={errors["first_name"]}
          touched={touched}
          value={values["first_name"]}
        />

        <FieldTextInput
          name="last_name"
          label={isEnglish ? "Last Name" : "Apellido"}
          autoComplete="family-name"
          onChange={handleChange}
          onBlur={handleBlur}
          dataValidation="name"
          autoCorrect="on"
          spellCheck={false}
          error={errors["last_name"]}
          touched={touched}
          value={values["last_name"]}
        />

        <FieldTextInput
          name="email"
          label={isEnglish ? "Email" : "Correo electrónico"}
          autoComplete="email"
          onChange={handleChange}
          onBlur={handleBlur}
          dataValidation="email"
          autoCorrect="on"
          spellCheck={false}
          error={errors["email"]}
          touched={touched}
          value={values["email"]}
        />

        {campaign.capture_phone ? (
          <FieldTextInput
            name="phone"
            label={isEnglish ? "Phone" : "Teléfono"}
            onChange={handleChange}
            onBlur={handleBlur}
            dataValidation="intlphone"
            error={errors["phone"]}
            touched={touched}
            value={values["phone"]}
          />
        ) : null}
      </fieldset>
      {campaign.capture_address && (
        <>
          {errors.address && typeof errors.address === "string" && (
            <p id="address-error">
              {isEnglish
                ? "Address not found. Please enter a valid address."
                : "Dirección no encontrada. Por favor ingrese una dirección válida."}
            </p>
          )}

          <fieldset
            id="address"
            className={classNames(
              "address",
              values.address?.["country"] &&
                !countrySubfieldConfig.adminAreas?.length &&
                "no-admins"
            )}
          >
            <FieldSelectInput
              name="address[country]"
              value={values.address?.["country"]}
              error={errors.address?.["country"]}
              id="country"
              autoComplete="country-name"
              required
              options={getCountries(campaign)}
              dataValidation="select"
              label={isEnglish ? "Country" : "País"}
              touched={touched}
              onBlur={handleBlur}
              onChange={(options) => (e) => {
                // we need to run a few checks on the country
                const country = options.find((c) => c.code === e.target.value)

                setCountrySubfieldConfig({
                  adminAreaLabel: country?.administrative_area_label || "State",
                  adminAreas: country?.administrative_areas,
                  hasPostalCode: country?.has_postal_code,
                  countryCode: country?.code || "us"
                })

                handleChange(e)
              }}
              renderOptions={(options: Array<Record<string, string>>) =>
                options.map((country, index) => {
                  if (country.code) {
                    return (
                      <option
                        key={country.code}
                        value={country.code}
                        label={country.name}
                      >
                        {country.name}
                      </option>
                    )
                  } else {
                    return (
                      <option key={index + "-disabled"} disabled>
                        ────────────────────
                      </option>
                    )
                  }
                })
              }
            />
            <FieldTextInput
              name="address[address1]"
              label={isEnglish ? "Address Line 1" : "Dirección linea 1"}
              onChange={handleChange}
              onBlur={handleBlur}
              dataValidation="notempty"
              error={errors.address?.["address1"]}
              touched={touched}
              value={values.address?.["address1"]}
            />
            <FieldTextInput
              name="address[address2]"
              label={isEnglish ? "Address Line 2" : "Dirección linea 2"}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.address?.["address2"]}
              touched={touched}
              value={values.address?.["address2"]}
              required={false}
            />
            <FieldTextInput
              id="city"
              name="address[city]"
              className="input-50"
              label={isEnglish ? "City" : "Ciudad"}
              onChange={handleChange}
              onBlur={handleBlur}
              dataValidation="notempty"
              error={errors.address?.["city"]}
              touched={touched}
              value={values.address?.["city"]}
            />
            {countrySubfieldConfig.adminAreas
              ? renderAdminAreas(countrySubfieldConfig.adminAreas)
              : null}
            {countrySubfieldConfig.hasPostalCode && (
              <FieldTextInput
                name="address[postal_code]"
                id="postal-code"
                className="postal-code"
                label={isEnglish ? "Postal Code" : "Código Postal"}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.address?.["postal_code"]}
                touched={touched}
                value={values.address?.["postal_code"]}
                required={countrySubfieldConfig.hasPostalCode}
                dataValidation={
                  countrySubfieldConfig.hasPostalCode
                    ? "postalcode." + countrySubfieldConfig.countryCode
                    : "alphanumeric"
                }
              />
            )}
          </fieldset>
        </>
      )}
      {(!free || !campaign.offer) && <Payment campaign={campaign} />}

      {campaign.offer && campaign.offer.bundle ? (
        <>
          <fieldset id="bundle-code">
            <FieldTextInput
              name="bundle_code"
              id="bundle-code-input"
              label={"Unique Code"}
              type="text"
              required
              data-type="fixed"
              data-mask="XXXX-XXXX-XXXX-XXXX"
              data-hint="####-####-####-####"
              onChange={handleChange}
              onBlur={handleBlur}
              data-validation="uniquecode"
              spellCheck={false}
              data-strip-mask={false}
              error={errors.bundle_code}
              touched={touched}
              value={values["bundle_code"]}
            />
          </fieldset>
        </>
      ) : null}
      
      <GiftSummary enableDonorCoveredFees={campaign.enable_donor_covered_fees} />
      <button
        id="submit-btn"
        className={classNames(`btn mb-4 block ${isSubmitting && "submitting"}`)}
        disabled={!isFormValid || isSubmitting}
        type="submit"
        data-testid="submit-button"
      >
        {isSubmitting && (
          <span id="loader">
            <em className="uno"></em>
            <em className="dos"></em>
            <em className="tres"></em>
          </span>
        )}
        {isSubmitting
          ? isEnglish
            ? "Submitting"
            : "Enviando"
          : free
          ? isEnglish
            ? "Claim My Gift"
            : "Claim My Gift"
          : isEnglish
          ? "Submit My Gift"
          : "Enviar Mi Regalo"}
      </button>

      {!donationTodayAmountInt ? (
        ""
      ) : campaign.offer && !free ? (
          (!campaign.offer?.allowed_products || (campaign?.offer?.allowed_products && context?.selectedProduct))
          && <p id="tax-statement" className="ss5 center">
          Your tax-deductible amount is{" "}
          <em id="deductible-amount">{taxDeductibleAmount}</em>, which is the
          difference of your <em id="gift-amount">{displayAmount(donationTodayAmountInt)}</em> gift over
          the <em id="good-faith-estimate">{fairMarketValue}</em> good faith
          estimate of this offer{mpBenefitsText}.
        </p>
      ) : mpBenefitsFairMarketValue ? (
        <p id="tax-statement" className="ss5 center">
          Your tax-deductible amount is <em id="deductible-amount">{taxDeductibleAmount}</em>,
          which is the difference of your <em id="gift-amount">{displayAmount(donationTodayAmountInt)}</em> gift
          over the <em id="good-faith-estimate">{mpBenefitsFairMarketValue}</em> fair market
          value of Ministry Partner exclusive benefits.
        </p>
      ) : !free ? (
        isEnglish ? (
          <p id="tax-statement" className="ss5 center">
            Your <em id="gift-amount">{displayAmount(donationTodayAmountInt)}</em> gift is
            tax-deductible as allowed by law.
          </p>
        ) : (
          <p id="tax-statement" className="ss5 center">
            Tu donación de <em id="gift-amount">{displayAmount(donationTodayAmountInt)}</em> es
            deducible de impuestos según lo permita la ley.
          </p>
        )
      ) : null}
    </>
  )
}
