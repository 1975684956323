import Image from "next/image"
import ReactHtmlParser from "react-html-parser"
import { Campaign } from "../../types"
import {
  getBadge,
  getDisclaimer,
  getHero,
  getHeroImg,
  getImgIXUrl,
  isDigital,
  isDownloadable,
  isShippable
} from "../../utils/helpers"
import Countdown from "./Countdown"

export default function ValuePropCampaignOffer({
  campaign
}: {
  campaign: Campaign
}) {
  const hero = getHero(campaign)

  return (
    <>
      <div className="offer-value-proposition pa-5 mt-4">
        {campaign.offer.products.length >= 1 ? (
          <>
            <h2 className="format center">
              <span>
                {ReactHtmlParser(
                  getBadge(campaign.offer.products[0], campaign)
                )}
              </span>
            </h2>
            {campaign.offer.image && (
              <div className="image-object-wrapper">
                <Image
                  layout="responsive"
                  width={560}
                  height={350}
                  src={getImgIXUrl(campaign.offer.image, "large")}
                  alt={campaign.offer.products[0].name}
                  objectFit="contain"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <h2 className="format center">
              <span>{ReactHtmlParser(getBadge(hero, campaign))}</span>
            </h2>
            <div
              className={
                isDigital(campaign)
                  ? "image-object-wrapper digital-wrapper"
                  : ""
              }
            >
              <Image
                layout="responsive"
                width={560}
                height={350}
                src={getHeroImg(campaign)}
                alt={hero.name}
                className={isDigital(campaign) ? "digital" : ""}
                objectFit="contain"
              />
            </div>
          </>
        )}
        <Countdown campaign={campaign} />
        <p className="ss5 center">
          {getDisclaimer(
            campaign,
            isShippable(campaign),
            isDownloadable(campaign.offer.products[0])
          )}
        </p>
      </div>
    </>
  )
}
