import { useEffect, useState } from "react"
import axios from "axios"
import { UserData } from "../pages/api/user"
import { useSession } from "next-auth/react"

interface UseUserState {
  isLoading: boolean | null
  error: string | null
  user: UserData | null
}

export const useUserData = () => {
  const [userState, setUserState] = useState<UseUserState>({
    user: null,
    isLoading: true,
    error: null
  })
  const { data: session } = useSession()
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data: userData } = await axios.get(`/api/user`)
        setUserState({
          user: userData,
          isLoading: null,
          error: null
        })
      } catch (e) {
        setUserState({
          isLoading: false,
          error: "An error occured fetching user data",
          user: null
        })
      }
    }

    if (session) {
      // now we want to query the API
      setUserState({
        ...userState,
        isLoading: true,
        error: null
      })

      fetchUserData()
    } else {
      // if there's no session, set loading to false
      setUserState({
        ...userState,
        isLoading: false,
        error: null
      })
    }
  }, [session])

  return userState
}
