import { Campaign } from "../../types"

export default function CampaignOfferProduct({
  campaign
}: {
  campaign: Campaign
}) {
  return campaign.offer && !campaign.offer.allowed_products ? (
    <>
      {campaign.offer.products.map((product) => {
        if (product.variations) {
          return product.variations.map((variation) => (
            <input
              key={variation.code}
              type="hidden"
              name="product_codes[]"
              value={variation.code}
            />
          ))
        } else {
          return (
            <input
              key={product.code}
              type="hidden"
              name="product_codes[]"
              value={product.code}
            />
          )
        }
      })}
    </>
  ) : null
}
