import { useContext, useLayoutEffect, useState } from "react"
import { Campaign } from "../../types"
import { useUserData } from "../../hooks/useUserData"
import { connect } from "formik"
import { XStateContext } from "../../contexts/XStateCtx"
import { useSelector } from "@xstate/react"
import { PaymentMethods } from "./PaymentMethods"

function IFrameComponent({ campaign }: { campaign: Campaign }) {
  const ENV = process.env.NEXT_PUBLIC_ENV

  const [isHidden, setIsHidden] = useState(false)

  const xStateServices = useContext(XStateContext)
  const paymentMethodsContext = useSelector(
    xStateServices.paymentMethodsService,
    selector
  )

  useLayoutEffect(() => {
    const isHidden =
      paymentMethodsContext.selectedPaymentMethod !== NEW_PAYMENT_METHOD

    setIsHidden(isHidden)
  }, [paymentMethodsContext])

  let iframeStyle = { display: isHidden ? "none" : "block" }

  if (ENV !== "production") {
    // if spanish lang return early
    if (campaign.language === "es-US") {
      return (
        <iframe
          style={iframeStyle}
          title="información de pago para los ministerios Ligonier en las pruebas"
          id="payment_iframe"
          frameBorder="0"
          src="https://cert.payconex.net/iframe/styled.php?aid=220614963161&lang=es&cvv=required&expy=single_input&timeout=60&css=eyJjbGFzc19pbnB1dF9ib3giOiJvdXRsaW5lOiBub25lO2JvcmRlcjpub25lO3dpZHRoOiAxMDAlO2JveC1zaXppbmc6IGJvcmRlci1ib3g7cGFkZGluZzogMTJweDt0ZXh0LWFsaWduOiBsZWZ0O2JhY2tncm91bmQ6ICNmNWY1ZjU7aGVpZ2h0OiA1NHB4O2JvcmRlci10b3A6IDFweCBzb2xpZCAjZTNlM2UzO2NvbG9yOiAjOTE5MTkxO2ZvbnQtc2l6ZTogMTRweDtmb250LWZhbWlseTonTHVjaWRhIFNhbnMgVW5pY29kZScsICdMdWNpZGEgR3JhbmRlJywgc2Fucy1zZXJpZjsiLCJpZF9mb3JtIjoiYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTtib3JkZXItcmFkaXVzOiAycHg7Ym9yZGVyOiAxcHggc29saWQgI2UzZTNlMztib3JkZXItdG9wOiBub25lOyJ9&text=eyJleHB5X3NpbmdsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyIjoiRXhwaXJhY2nDs24gKE1NQUEpIn0sImV4cHlfZG91YmxlIjp7ImxhYmVsIjoiIiwicGxhY2Vob2xkZXJfMSI6IkNhcmQgTW9udGggKG1tKSIsInBsYWNlaG9sZGVyXzIiOiJDYXJkIFllYXIgKHl5KSJ9LCJudW1iZXIiOnsibGFiZWwiOiIiLCJwbGFjZWhvbGRlciI6Ik7Dum1lcm8gZGUgdGFyamV0YSJ9LCJjdnYiOnsibGFiZWwiOiIiLCJwbGFjZWhvbGRlciI6IkPDs2RpZ28gZGUgU2VndXJpZGFkIn19%3D%3D&font=dW5kZWZpbmVk&payment_method=cc"
        ></iframe>
      )
    }

    return campaign.brand === "Ligonier Ministries Canada" ? (
      <iframe
        style={iframeStyle}
        title="payment information for Ligonier Canada stage"
        id="payment_iframe"
        frameBorder="0"
        src="https://cert.payconex.net/iframe/styled.php?aid=220614983741&lang=en&cvv=required&expy=single_input&timeout=60&css=eyJjbGFzc19pbnB1dF9ib3giOiJvdXRsaW5lOiBub25lO2JvcmRlcjpub25lO3dpZHRoOiAxMDAlO2JveC1zaXppbmc6IGJvcmRlci1ib3g7cGFkZGluZzogMTJweDt0ZXh0LWFsaWduOiBsZWZ0O2JhY2tncm91bmQ6ICNmNWY1ZjU7aGVpZ2h0OiA1NHB4O2JvcmRlci10b3A6IDFweCBzb2xpZCAjZTNlM2UzO2NvbG9yOiAjOTE5MTkxO2ZvbnQtc2l6ZTogMTRweDtmb250LWZhbWlseTonTHVjaWRhIFNhbnMgVW5pY29kZScsICdMdWNpZGEgR3JhbmRlJywgc2Fucy1zZXJpZjsiLCJpZF9mb3JtIjoiYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTtib3JkZXItcmFkaXVzOiAycHg7Ym9yZGVyOiAxcHggc29saWQgI2UzZTNlMztib3JkZXItdG9wOiBub25lOyJ9&text=eyJleHB5X3NpbmdsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyIjoiTU1ZWSJ9LCJleHB5X2RvdWJsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyXzEiOiJDYXJkIE1vbnRoIChtbSkiLCJwbGFjZWhvbGRlcl8yIjoiQ2FyZCBZZWFyICh5eSkifSwibnVtYmVyIjp7ImxhYmVsIjoiIiwicGxhY2Vob2xkZXIiOiJDYXJkIE51bWJlciJ9LCJjdnYiOnsibGFiZWwiOiIiLCJwbGFjZWhvbGRlciI6IkNWQyJ9fQ%3D%3D&font=dW5kZWZpbmVk&payment_method=cc"
      ></iframe>
    ) : (
      <iframe
        style={iframeStyle}
        title="payment information for Ligonier stage"
        id="payment_iframe"
        frameBorder="0"
        src="https://cert.payconex.net/iframe/styled.php?aid=220614963161&lang=en&cvv=required&expy=single_input&timeout=60&css=eyJjbGFzc19pbnB1dF9ib3giOiJvdXRsaW5lOiBub25lO2JvcmRlcjpub25lO3dpZHRoOiAxMDAlO2JveC1zaXppbmc6IGJvcmRlci1ib3g7cGFkZGluZzogMTJweDt0ZXh0LWFsaWduOiBsZWZ0O2JhY2tncm91bmQ6ICNmNWY1ZjU7aGVpZ2h0OiA1NHB4O2JvcmRlci10b3A6IDFweCBzb2xpZCAjZTNlM2UzO2NvbG9yOiAjOTE5MTkxO2ZvbnQtc2l6ZTogMTRweDtmb250LWZhbWlseTonTHVjaWRhIFNhbnMgVW5pY29kZScsICdMdWNpZGEgR3JhbmRlJywgc2Fucy1zZXJpZjsiLCJpZF9mb3JtIjoiYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTtib3JkZXItcmFkaXVzOiAycHg7Ym9yZGVyOiAxcHggc29saWQgI2UzZTNlMztib3JkZXItdG9wOiBub25lOyJ9&text=eyJleHB5X3NpbmdsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyIjoiTU1ZWSJ9LCJleHB5X2RvdWJsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyXzEiOiJDYXJkIE1vbnRoIChtbSkiLCJwbGFjZWhvbGRlcl8yIjoiQ2FyZCBZZWFyICh5eSkifSwibnVtYmVyIjp7ImxhYmVsIjoiIiwicGxhY2Vob2xkZXIiOiJDYXJkIE51bWJlciJ9LCJjdnYiOnsibGFiZWwiOiIiLCJwbGFjZWhvbGRlciI6IkNWQyJ9fQ%3D%3D&font=dW5kZWZpbmVk&payment_method=cc"
      ></iframe>
    )
  } else {
    // if spanish language return early
    if (campaign.language === "es-US") {
      return (
        <iframe
          style={iframeStyle}
          title="información de pago para los ministerios Ligonier"
          id="payment_iframe"
          frameBorder="0"
          src="https://secure.payconex.net/iframe/styled.php?aid=120614971544&lang=en&cvv=required&expy=single_input&timeout=60&css=eyJjbGFzc19pbnB1dF9ib3giOiJvdXRsaW5lOiBub25lO2JvcmRlcjpub25lO3dpZHRoOiAxMDAlO2JveC1zaXppbmc6IGJvcmRlci1ib3g7cGFkZGluZzogMTJweDt0ZXh0LWFsaWduOiBsZWZ0O2JhY2tncm91bmQ6ICNmNWY1ZjU7aGVpZ2h0OiA1NHB4O2JvcmRlci10b3A6IDFweCBzb2xpZCAjZTNlM2UzO2NvbG9yOiAjOTE5MTkxO2ZvbnQtc2l6ZTogMTRweDtmb250LWZhbWlseTonTHVjaWRhIFNhbnMgVW5pY29kZScsICdMdWNpZGEgR3JhbmRlJywgc2Fucy1zZXJpZjsiLCJpZF9mb3JtIjoiYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTtib3JkZXItcmFkaXVzOiAycHg7Ym9yZGVyOiAxcHggc29saWQgI2UzZTNlMztib3JkZXItdG9wOiBub25lOyJ9&text=eyJleHB5X3NpbmdsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyIjoiRXhwaXJhY2nDs24gKE1NQUEpIn0sImV4cHlfZG91YmxlIjp7ImxhYmVsIjoiIiwicGxhY2Vob2xkZXJfMSI6IkNhcmQgTW9udGggKG1tKSIsInBsYWNlaG9sZGVyXzIiOiJDYXJkIFllYXIgKHl5KSJ9LCJudW1iZXIiOnsibGFiZWwiOiIiLCJwbGFjZWhvbGRlciI6Ik7Dum1lcm8gZGUgdGFyamV0YSJ9LCJjdnYiOnsibGFiZWwiOiIiLCJwbGFjZWhvbGRlciI6IkPDs2RpZ28gZGUgU2VndXJpZGFkIn19%3D%3D&font=dW5kZWZpbmVk&payment_method=cc"
        ></iframe>
      )
    }

    return campaign.brand === "Ligonier Ministries Canada" ? (
      <iframe
        style={iframeStyle}
        title="payment information for Ligonier Canada"
        id="payment_iframe"
        frameBorder="0"
        src="https://secure.payconex.net/iframe/styled.php?aid=120615422401&lang=en&cvv=required&expy=single_input&timeout=60&css=eyJjbGFzc19pbnB1dF9ib3giOiJvdXRsaW5lOiBub25lO2JvcmRlcjpub25lO3dpZHRoOiAxMDAlO2JveC1zaXppbmc6IGJvcmRlci1ib3g7cGFkZGluZzogMTJweDt0ZXh0LWFsaWduOiBsZWZ0O2JhY2tncm91bmQ6ICNmNWY1ZjU7aGVpZ2h0OiA1NHB4O2JvcmRlci10b3A6IDFweCBzb2xpZCAjZTNlM2UzO2NvbG9yOiAjOTE5MTkxO2ZvbnQtc2l6ZTogMTRweDtmb250LWZhbWlseTonTHVjaWRhIFNhbnMgVW5pY29kZScsICdMdWNpZGEgR3JhbmRlJywgc2Fucy1zZXJpZjsiLCJpZF9mb3JtIjoiYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTtib3JkZXItcmFkaXVzOiAycHg7Ym9yZGVyOiAxcHggc29saWQgI2UzZTNlMztib3JkZXItdG9wOiBub25lOyJ9&text=eyJleHB5X3NpbmdsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyIjoiTU1ZWSJ9LCJleHB5X2RvdWJsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyXzEiOiJDYXJkIE1vbnRoIChtbSkiLCJwbGFjZWhvbGRlcl8yIjoiQ2FyZCBZZWFyICh5eSkifSwibnVtYmVyIjp7ImxhYmVsIjoiIiwicGxhY2Vob2xkZXIiOiJDYXJkIE51bWJlciJ9LCJjdnYiOnsibGFiZWwiOiIiLCJwbGFjZWhvbGRlciI6IkNWQyJ9fQ%3D%3D&font=dW5kZWZpbmVk&payment_method=cc"
      ></iframe>
    ) : campaign.brand === "Reformation Bible College" ? (
      <iframe
        style={iframeStyle}
        title="payment information for Reformation Bible College"
        id="payment_iframe"
        frameBorder="0"
        src="https://secure.payconex.net/iframe/styled.php?aid=120615422421&lang=en&cvv=required&expy=single_input&timeout=60&css=eyJjbGFzc19pbnB1dF9ib3giOiJvdXRsaW5lOiBub25lO2JvcmRlcjpub25lO3dpZHRoOiAxMDAlO2JveC1zaXppbmc6IGJvcmRlci1ib3g7cGFkZGluZzogMTJweDt0ZXh0LWFsaWduOiBsZWZ0O2JhY2tncm91bmQ6ICNmNWY1ZjU7aGVpZ2h0OiA1NHB4O2JvcmRlci10b3A6IDFweCBzb2xpZCAjZTNlM2UzO2NvbG9yOiAjOTE5MTkxO2ZvbnQtc2l6ZTogMTRweDtmb250LWZhbWlseTonTHVjaWRhIFNhbnMgVW5pY29kZScsICdMdWNpZGEgR3JhbmRlJywgc2Fucy1zZXJpZjsiLCJpZF9mb3JtIjoiYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTtib3JkZXItcmFkaXVzOiAycHg7Ym9yZGVyOiAxcHggc29saWQgI2UzZTNlMztib3JkZXItdG9wOiBub25lOyJ9&text=eyJleHB5X3NpbmdsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyIjoiTU1ZWSJ9LCJleHB5X2RvdWJsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyXzEiOiJDYXJkIE1vbnRoIChtbSkiLCJwbGFjZWhvbGRlcl8yIjoiQ2FyZCBZZWFyICh5eSkifSwibnVtYmVyIjp7ImxhYmVsIjoiIiwicGxhY2Vob2xkZXIiOiJDYXJkIE51bWJlciJ9LCJjdnYiOnsibGFiZWwiOiIiLCJwbGFjZWhvbGRlciI6IkNWQyJ9fQ%3D%3D&font=dW5kZWZpbmVk&payment_method=cc"
      ></iframe>
    ) : (
      <iframe
        style={iframeStyle}
        title="payment information for Ligonier"
        id="payment_iframe"
        frameBorder="0"
        src="https://secure.payconex.net/iframe/styled.php?aid=120614971544&lang=en&cvv=required&expy=single_input&timeout=60&css=eyJjbGFzc19pbnB1dF9ib3giOiJvdXRsaW5lOiBub25lO2JvcmRlcjpub25lO3dpZHRoOiAxMDAlO2JveC1zaXppbmc6IGJvcmRlci1ib3g7cGFkZGluZzogMTJweDt0ZXh0LWFsaWduOiBsZWZ0O2JhY2tncm91bmQ6ICNmNWY1ZjU7aGVpZ2h0OiA1NHB4O2JvcmRlci10b3A6IDFweCBzb2xpZCAjZTNlM2UzO2NvbG9yOiAjOTE5MTkxO2ZvbnQtc2l6ZTogMTRweDtmb250LWZhbWlseTonTHVjaWRhIFNhbnMgVW5pY29kZScsICdMdWNpZGEgR3JhbmRlJywgc2Fucy1zZXJpZjsiLCJpZF9mb3JtIjoiYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTtib3JkZXItcmFkaXVzOiAycHg7Ym9yZGVyOiAxcHggc29saWQgI2UzZTNlMztib3JkZXItdG9wOiBub25lOyJ9&text=eyJleHB5X3NpbmdsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyIjoiTU1ZWSJ9LCJleHB5X2RvdWJsZSI6eyJsYWJlbCI6IiIsInBsYWNlaG9sZGVyXzEiOiJDYXJkIE1vbnRoIChtbSkiLCJwbGFjZWhvbGRlcl8yIjoiQ2FyZCBZZWFyICh5eSkifSwibnVtYmVyIjp7ImxhYmVsIjoiIiwicGxhY2Vob2xkZXIiOiJDYXJkIE51bWJlciJ9LCJjdnYiOnsibGFiZWwiOiIiLCJwbGFjZWhvbGRlciI6IkNWQyJ9fQ%3D%3D&font=dW5kZWZpbmVk&payment_method=cc"
      ></iframe>
    )
  }
}

export const NEW_PAYMENT_METHOD = "newPaymentMethod"

const selector = ({ context }) => {
  return {
    userPaymentMethods: context.userPaymentMethods,
    selectedPaymentMethod: context.selectedPaymentMethod
  }
}

export const Payment = connect(({ campaign }: { campaign: Campaign }) => {
  const xStateServices = useContext(XStateContext)
  const paymentMethodsContext = useSelector(
    xStateServices.paymentMethodsService,
    selector
  )
  const { send } = xStateServices.paymentMethodsService

  const [hasSetPaymentMethods, setHasSetPaymentMethods] = useState(false)
  const { user } = useUserData()

  const isCanadaOrRBC =
    campaign.brand === "Ligonier Ministries Canada" ||
    campaign.brand === "Reformation Bible College"

  const userPaymentMethods = user?.payment_methods

  useLayoutEffect(() => {
    if (
      userPaymentMethods &&
      !paymentMethodsContext.userPaymentMethods &&
      !hasSetPaymentMethods &&
      !campaign?.offer?.free &&
      !campaign?.offer?.new_users_only &&
      !isCanadaOrRBC
    ) {
      send({
        type: "SET_USER_PAYMENT_METHODS",
        data: userPaymentMethods
      })
      // Select first payment method on load fi there is one
      if (userPaymentMethods[0]?.id) {
        send({
          type: "SET_SELECTED_PAYMENT_METHOD",
          data: `${userPaymentMethods[0]?.id}`
        })
      }

      setHasSetPaymentMethods(true)
    }
  }, [userPaymentMethods, hasSetPaymentMethods, setHasSetPaymentMethods])

  return (
    <div className="payment">
      <PaymentMethods
        paymentMethods={paymentMethodsContext.userPaymentMethods}
      />
      <IFrameComponent campaign={campaign} />
    </div>
  )
})
