import { useState } from "react"
import { Campaign } from "../types"
import { getShareHref } from "../utils/helpers"

export default function Share({ campaign }: { campaign: Campaign }) {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  return (
    <>
      {campaign.brand != "Ministry Partners" ? (
        <div className={showModal ? "modal" : null}>
          <div id="modal">
            <div id="modal-content">
              <button id="modal-close-btn" onClick={toggleModal}>
                &#xe117;
              </button>
              <h2>Share</h2>
              <nav id="share-to">
                <a
                  data-icon="&#xe60e;"
                  href={getShareHref(campaign, "facebook")}
                  target="_blank"
                  rel="noreferrer"
                >
                  facebook
                </a>
                <a
                  data-icon="&#xe610;"
                  href={getShareHref(campaign, "twitter")}
                  target="_blank"
                  rel="noreferrer"
                >
                  twitter
                </a>
                <a data-icon="&#xe901;" href={getShareHref(campaign, "email")}>
                  Email
                </a>
              </nav>
            </div>
          </div>
          <div id="modal-overlay"></div>
        </div>
      ) : null}
    </>
  )
}
