import React from "react"

export function ECFA() {
  return (
    <div className="col-40 col-100-m right center-m col-order-1-m">
      <div className="seals">
        <span className="ssl-logo">SSL Secure</span>
        <a
          className="ecfa-logo"
          href="http://www.ecfa.org/MemberProfile.aspx?ID=5396"
          target="_blank"
          rel="noreferrer"
        >
          ECFA Charter Member
        </a>
      </div>
    </div>
  )
}
