import { getPreface } from "../../utils/helpers"

export const CampaignHeader = ({ campaign }) => {
  return (
    <header id="gift-header" className="row cols-middle">
      <a className="logo col pa-0" href={process.env.NEXT_PUBLIC_DOMAIN}>
        <span className="sr-only">logo</span>
      </a>
      <h2 className="proposition col col-auto pa-0">
        {getPreface(campaign)} <em>{campaign.brand}</em>
      </h2>
      <span className="security col pa-0">l</span>
    </header>
  )
}
