import { useContext, useEffect, useState } from "react"
import { DonationAmountContext, RecurringContext } from "../machines"
import { cleanAmount } from "../utils/helpers"
import { ServiceContext } from "../pages/[id]/[slug]"
import { minimumMinistryPartnerDonation } from "../utils/helpers"

const DONATION_FEES_PERCENT = 3

export const useDonationAmount = () => {
  const { donationAmountService, recurringService, productCodesService } =
    useContext(ServiceContext)

  const [donationAmountContext, setDonationAmountContext] =
    useState<DonationAmountContext | null>(null)
  const [recurringContext, setRecurringContext] =
    useState<RecurringContext | null>(null)

  useEffect(() => {
    const donationSubscription = donationAmountService.subscribe((state) => {
      setDonationAmountContext(state.context)
    })

    const recurringSubscription = recurringService.subscribe((state) => {
      setRecurringContext(state.context)
    })
    return () => {
      donationSubscription.unsubscribe()
      recurringSubscription.unsubscribe()
    }
  }, [donationAmountService, recurringService, productCodesService])

  const oneTimeGiftAmount = cleanAmount(
    donationAmountContext?.values?.amount ||
    donationAmountContext?.values?.amountSelected
  ) ?? "0.00"
  const hasNonzeroOneTimeGiftAmount = !!parseFloat(oneTimeGiftAmount)
  const isRecurring = recurringContext?.values?.recurring
  const recurOnDay = recurringContext?.values?.recurOnDay
  const recurStartDate = recurringContext?.values?.recurStartDate
  const recurringGiftAmount = cleanAmount(
    recurringContext?.values?.amount || 
    recurringContext?.values?.amountSelected
  ) ?? "0.00"
  const hasNonzeroRecurringGiftAmount = !!parseFloat(recurringGiftAmount)
  const isRecurringAndHasValue = isRecurring && hasNonzeroRecurringGiftAmount

  const isDonating = hasNonzeroOneTimeGiftAmount || isRecurringAndHasValue

  const donationTodayAmount = isRecurring ? recurringGiftAmount : oneTimeGiftAmount

  const isMinistryPartnerAndHasValue = isRecurring && parseInt(recurringGiftAmount) >= minimumMinistryPartnerDonation && recurringContext?.values?.ministryPartnerChecked

  const donationFeesCovered = donationAmountContext?.values?.donationFeesCovered ?? false
  const feesMultiplier = DONATION_FEES_PERCENT / 100  // Used to calculate what the fees would be if covered
  const feesAndAmountMultiplier = donationFeesCovered ? (1 + feesMultiplier) : 1  // Use to calculate the amount, including fees if the user chose to do so, not including them if not

  const feesCalculator = (amount) => (Number(amount) * feesMultiplier).toFixed(2)
  const feesAndAmountCalculator = (amount) => (Number(amount) * feesAndAmountMultiplier).toFixed(2)

  const result = {
    oneTimeGiftAmount,
    donationTodayAmount,
    donationFeesCovered,
    feesCalculator,
    feesAndAmountCalculator,
    isRecurring,
    recurOnDay,
    recurStartDate,
    recurringGiftAmount,
    isDonating,
    isMinistryPartnerAndHasValue,
  }
  // console.log(result)
  return result
}
