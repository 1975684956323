export const MaintenanceMode = () => {
  return (
    <div className="maintenance-mode">
      <div className="maintenance-logo mb-2"></div>
      <h1 className="t1 center mb-2">Offline for maintenance</h1>
      <p className="ss2">This app is undergoing maintenance right now</p>
      <p className="ss2">Please check back later</p>
    </div>
  )
}
