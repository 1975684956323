export const HiddenInputFields = ({
  urlAmount,
  campaign,
  recurringSelected
}) => {
  return (
    <>
      <input type="hidden" name="audit" value="" />
      <input
        type="hidden"
        name="campaign_id"
        value={campaign.id}
        id="campaign-id"
      />
      <input
        type="hidden"
        name="campaign_language"
        value={campaign.language}
        id="campaign-language"
      />
      <input
        type="hidden"
        name="donation[now]"
        value="true"
        id="donation-now-hidden"
      />
      <input
        type="hidden"
        name="donation[amount]"
        value={urlAmount || "0"}
        id="amount-hidden"
      />
      <input
        type="hidden"
        name="is_recurring"
        value={recurringSelected || false}
        id="recurring-toggle-hidden"
      />
    </>
  )
}
