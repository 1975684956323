import React from "react"

export function Recaptcha({ spanish = false }: { spanish?: boolean }) {
  return spanish ? (
    <div className="col-100 col-order-3-m">
      <p className="ss5 center-m">
        Este sitio está protegido por reCAPTCHA y se aplican la{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Política de privacidad
        </a>{" "}
        y las{" "}
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Condiciones de servicio
        </a>{" "}
        de Google.
      </p>
    </div>
  ) : (
    <div className="col-100 col-order-3-m">
      <p className="ss5 center-m">
        This site is protected by reCAPTCHA and the Google{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{" "}
        apply.
      </p>
    </div>
  )
}
